.content.app-content {
  overflow: overlay;
  overflow-x: hidden;
  padding: 0 !important;
}

html .content.app-content .content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 41, 47, 0.5);
  cursor: pointer;
  -webkit-transition: all 0.7s, z-index 0s;
  transition: all 0.7s, z-index 0s;
  z-index: -1;
}

html .blank-page .content-wrapper {
  padding: 0 !important;
  
}

.login{
  margin: '50% auto 50px' !important;
              padding: '4rem' !important;
}

@media (max-width: 500px) {  
  .loging-bg{
    display: none !important;
  }
}

.auth-wrapper.auth-v2 {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.auth-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.auth-wrapper.auth-v2 .auth-inner {
  height: 100vh;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100);
}

.auth-wrapper.auth-v2 .brand-logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0;
  z-index: 1;
}

.auth-wrapper .brand-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1rem 0 2rem 0;
}

.p-5 {
  padding: 4rem !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.pl-5, .px-5 {
  padding-left: 4rem !important;
}
.pr-5, .px-5 {
  padding-right: 4rem !important;
}
.w-100 {
  width: 100% !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* margin-right: -1rem;
  margin-left: -1rem; */
}

.container {
  width: 100% !important;
  max-width: 100% !important;
   height: calc(var(--vh, 1vh) * 100) !important;
}

.form-group {
  margin-bottom: 5px !important;
}

.bg{
  background: url('../../assets/images/bg.png');
    background-size: cover;
}
.sidebar-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.32);
  display: none;
  transition: 1s ease-in-out;
  z-index: 1;
}

.show-overlay {
  display: block;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2;
  min-height: 100%;
  max-width: 60% !important;
  background-color: #096c93;
  transition: 0.5s ease-in-out;
  box-shadow: 4px 0px 24px 0px rgba(0, 0, 0, 0.32);
}

.sidebar-flex {
  position: relative !important;
  padding: 3em;
  height: 20em;
  line-height: 50px;
  color: white;
  transition: 0.5s ease-in-out;
}

.sidebar-flex a {
  color: rgba(255, 255, 255, 1);
  transition: 0.5s ease-in-out;
}

.sidebar-flex a:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  text-shadow: 0px 1px 8px rgba(255, 255, 255, 1);
  padding-left: 0.2em;
}

.sidebar-flex a:before {
  content: '';
  position: absolute;
  height: 1.4rem;
  margin-top: 0.9rem;
  width: 2%;
  left: -1em;
  visibility: hidden;
  background-color: #ec672a;
  transform: scaleX(0);
  border-radius: 2px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sidebar-flex a:hover:before {
  opacity: 1;
  visibility: visible;
  transform: scaleX(1);
}

.toggle-sidebar {
  top: 0;
  position: fixed !important;
  z-index: 3;
  background: inherit;
  cursor: pointer;
  margin: 20px;
  font-size: 1.5em;
}

.wecare-logo {
  width: 10rem !important;
}

.powered-by {
  font-size: 12px !important;
  position: absolute;
  bottom: 0;
  color: white;
}

/*
 * slide menu in from the left
 */
.active {
  left: 0;
}

.back-arrow {
  font-weight: 900;
  font-size: 24px;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 760px) {
  .sidebar-container {
    top: 0;
    left: 0%;
    min-height: 100%;
    max-width: 15% !important;
    color: white !important;
    background-color: #096c93;
    transition: 0.5s ease-in-out;
    box-shadow: none;
    padding-left: 1.8em !important;
    font-size: 1rem;
  }

  .toggle-sidebar {
    display: none;
  }
}

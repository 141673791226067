.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #444 !important;
    background: #F5F5F5 !important;
    transition: box-shadow 0.2s;
    font-size: 13px !important;
}

.p-paginator{
    background: #F5F5F5 !important;
    color: #444 !important;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem !important;
    font-size: 13px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.25rem 0.25rem 0.25rem 0.25rem !important;
    padding-bottom: 0.25rem !important;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    color: #444 !important;
    transition: box-shadow 0.2s;
    font-size: 13px !important;
}

.p-sortable-column-icon{
    color: #444 !important;
    font-size: 12px !important;
}

.p-datatable-emptymessage td{
    text-align: center !important;
    font-size: 12px !important;
}

.p-paginator .p-link{
    font-size: 12px !important;
}

.p-filter-column input{
    font-size: 12px !important;
    padding: 5px !important;
    width: 100% !important;
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.5) !important;
}

.react-confirm-alert-body > h1 {
    font-size: 18px !important;
    font-weight: 600;
}
.profile-page-icon {
  font-size: 68px;
}

.user-info {
  font-size: 14px !important;
}

.settings-page a {
  color: black;
}

.settings-page a:hover {
  color: black;
  text-decoration: none;
}

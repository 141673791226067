.MuiGrid-container {
  width: unset;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
}

.jss110 {
  justify-content: space-between;
}
.MuiGrid-grid-xl-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}
.MuiGrid-grid-xl-2{
  flex-grow: 0;
    max-width: 20%;
    flex-basis: 20%;
}
@media (min-width: 1200px)
{
.MuiGrid-grid-xl-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}
}
@media (min-width: 992px)
{
.MuiGrid-grid-lg-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}
}

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1200px)
{
.jss99 {
    margin-bottom: 0;
}

.jss99 {
    margin-bottom: 1.5rem;
}
}

.MuiCard-root {
    border: 0;
    display: flex;
    overflow: unset;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    border-radius: .375rem;
    flex-direction: column;
    background-clip: initial;
    background-color: #FFFFFF;
}

.MuiPaper-elevation0 {
    box-shadow: none;
}

.MuiPaper-rounded {
    border-radius: 4px;
}

.MuiPaper-root {
    color: #444;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background:  #fff;
  }

.MuiCardContent-root:last-child {
  padding-bottom: 24px;
}

.jss100 {
  padding: 1rem 1.5rem !important;
}

.MuiCardContent-root {
  flex: 1 1 auto;
  padding: 1.5rem;
  min-height: 1px;
}
.jss115 {
  color: rgb(136, 152, 170);
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  margin-top: 1rem;
  align-items: center;
  margin-bottom: 0px;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 14px;
  height: 14px;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.jss117 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
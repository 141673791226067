html{
    background-color: #eee;
}
p{
    font-size: 13px !important;
    margin: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}
.required{
    color: red;
}
.p-button{
    font-size: 12px !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1rem !important;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: rgb(0, 97, 174) !important;
    padding: 0.5rem !important;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    color: #fff !important;
    font-size: 12px !important;
}

.p-dialog-header-close-icon{
    color: #fff !important;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0.75rem 1.5rem 0.5rem 1.5rem !important;
}
.p-inputtext{
    font-size: 13px !important;
    padding: 0.4rem 0.4rem !important;
}

.inputWrapper {
    width: 150px;
    padding: 10px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /*Using a background color, but you can use a background image to represent a button*/
    background-color: #279ef9;
    color: #fff;
    font-size: 13px;
    margin:auto;
}
.fileInput {
    cursor: pointer;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    z-index: 99;
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    font-size:50px;
    /*Opacity settings for all browsers*/
    opacity: 0;
    -moz-opacity: 0;
    filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}

.container {
    width: 100% !important;
    max-width: 100% !important;
     height: calc(var(--vh, 1vh) * 80) !important;
  }

  .authContainer{
    width: 100% !important;
    max-width: 100% !important;
     height: calc(var(--vh, 1vh) * 100) !important;
  }

  .p-chips-multiple-container{
      width: 100% !important;
      font-size: 13px !important;
  }
  .pl-5, .px-5 {
    padding-left: 3rem !important;
}
.pr-5, .px-5 {
    padding-right: 3rem !important;
}
.w-100 {
    width: 100% !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
@media (min-width: 992px)
{
.d-lg-flex {
    display: flex !important;
}
}

.rc-time-picker-panel{
    z-index: 111111111111111 !important;
}